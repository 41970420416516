.cl-modalBackdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

.cl-signIn-root .cl-logoImage,
.cl-signUp-root .cl-logoImage {
  height: 64px;
}

.error-boundary {
  color: #fff;
}
.error-boundary .home-link {
  color: #fff;
}
